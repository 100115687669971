import React from 'react';
import { Layout } from '../components/shared/Layout';

const DisclaimerPage = (): JSX.Element => {
  return (
    <Layout>
      <main className="">
        <section className="bg-white py-8">
          <div className="container mx-auto max-w-xl">
            <h1>About Awesome Foundation The Hague</h1>
            <p>
              The Awesome Foundation The Hague is a local community that boosts
              ideas to make The Hague more awesome, cool, fun, loving and
              social. Multiple evenings a year, idea owners pitch their awesome
              idea to the audience. These evenings are called “Pitch Nights” and
              are free to attend.
            </p>

            <p>
              Twenty inhabitants of The Hague called “Trustees” vote for the
              winner of the €1.000 micro-grant. The Trustees have one thing in
              common: they each LOVE The Hague! Every Pitch Night they give away
              €50 each to make the city of The Hague more awesome.
            </p>

            <p>
              And another good thing about our trustees is their broad network.
              As a idea owner you can use their network to get in touch with
              companies or people that can bring your idea forward. So even if
              you don’t win the €1.000, we’ll help you realizing your awesome
              idea.
            </p>

            <h2 className="mt-16">The Global Awesome Foundation</h2>
            <p>
              The Awesome Foundation is a global community advancing the
              interest of awesome in the universe. Each fully autonomous chapter
              supports awesome projects through micro-grants. These
              micro-grants, €1000 or the local equivalent, come out of pockets
              of the chapter’s “Trustees” and are given on a no-strings-attached
              basis to people and groups working on awesome projects.
            </p>

            <p>
              Trustees support crazy ideas in more than 90 chapters in 13
              countries. Every few months 20 Trustees get together and
              contribute €50 to the cause (adding up to the €1,000 that we give
              out as a grant).
            </p>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default DisclaimerPage;
